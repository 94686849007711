import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubscriptionService } from "@/_services";

@Injectable({
    providedIn: "root",
})
export class RedirectGuard implements CanActivate {
    constructor(
        private router: Router,
        private subscriptionService: SubscriptionService,
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.subscriptionService.subscriptionData().pipe(
            map((subscription) => {
                if (subscription.isTrialActive()) {
                    return this.router.parseUrl("/welcome-trial");
                } else {
                    return this.router.parseUrl("/home");
                }
            }),
        );
    }
}
