import { Component, inject, OnInit } from "@angular/core";
import { PromotionService } from "@/_services/promotion.service";
import {
    BehaviorSubject,
    concat,
    Observable,
    of,
    shareReplay,
    tap,
} from "rxjs";
import { Promotion, PromotionStatus } from "@/_models/promotion.interface";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserDm } from "@/_models";
import { debounceTime, map, switchMap } from "rxjs/operators";
import UserRoles = UserDm.UserRoles;
import { FilterService } from "@/_services/filter.service";
import { AsyncPipe } from "@angular/common";
import { PaginatorModule } from "primeng/paginator";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { HomePromotionCardComponent } from "@/components/home/home-promotion-card/home-promotion-card.component";

@Component({
    selector: "app-home-promotion-list",
    standalone: true,
    imports: [
        AsyncPipe,
        PaginatorModule,
        SharedComponentsModule,
        HomePromotionCardComponent,
    ],
    templateUrl: "./home-promotion-list.component.html",
    styleUrl: "./home-promotion-list.component.scss",
})
export class HomePromotionListComponent implements OnInit {
    private formBuilder = inject(FormBuilder);
    private promoService = inject(PromotionService);
    private filterService = inject(FilterService);
    private activatedRoute = inject(ActivatedRoute);

    promoList$: Observable<Promotion[]>;

    private page$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    pageSize: number = 10;
    total: number = 0;

    filterForm: FormGroup;
    currentSorting: { key: string; value: string } = {
        key: "createdAt",
        value: "desc",
    };

    constructor() {}

    ngOnInit() {
        this.filterForm = this.formBuilder.group({
            status: [[PromotionStatus.Sent, PromotionStatus.Overdue]],
        });

        this.promoList$ = this.accumulatePromoList();
    }

    accumulatePromoList() {
        return concat(
            of(null), // reset the scroll container between filter changes
            this.page$.pipe(
                debounceTime(500),
                switchMap((page) => {
                    const filterParams = this.buildFilterQuery();
                    const sortParams = this.buildSortQuery();
                    return this.promoService.getPromotionList(
                        filterParams,
                        sortParams,
                        page,
                        this.pageSize,
                    );
                }),
                tap((list) => (this.total = list.meta["total"])),
                map((list) => list.data),
                shareReplay(),
            ),
        );
    }

    buildFilterQuery() {
        return this.filterService.buildFilterQuery(this.filterForm);
    }

    buildSortQuery() {
        const sortQuery = new URLSearchParams();
        if (this.currentSorting.key) {
            sortQuery.append(
                this.currentSorting.key,
                this.currentSorting.value,
            );
        }
        return sortQuery;
    }

    onPageChange(event: any) {
        this.page$.next(event.page);
    }
}
