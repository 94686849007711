import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "percentChange",
    standalone: true,
})
export class PercentChangePipe implements PipeTransform {
    transform(value: number | string): unknown {
        if (typeof value === "string") {
            const floatValue = parseFloat(value);
            if (isNaN(floatValue) || !isFinite(floatValue)) {
                return "--";
            }

            if (floatValue > 0) {
                return `+${floatValue}`;
            }
        }
        return value;
    }
}
