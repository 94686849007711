<ng-container *appDesktop>
    <app-page-topbar
        *ngIf="assessment$ | async as assessment"
        [title]="assessment?.title">

        <ng-container *ngIf="(isAtLeastAdmin$ | async)">
            <ng-container *ngIf="!(isEdited$ | async); else closeEditor">
                <div
                    class="secondary-button"
                    [routerLink]="'/assessments/' + assessment?._id + '/edit/questionnaire'">
                    <app-custom-icon
                        [iconPath]="'assets/icons/general-icons.svg'"
                        [imageName]="'icon-cog'">
                    </app-custom-icon>
                    Settings
                </div>
            </ng-container>

            <ng-template #closeEditor>
                <div
                    class="secondary-button"
                    [routerLink]="'/assessments/' + assessment?._id">
                    <app-custom-icon
                        [iconPath]="'assets/icons/general-icons.svg'"
                        [imageName]="'icon-close'">
                    </app-custom-icon>
                    <ng-container *appDesktop>Settings</ng-container>
                </div>
            </ng-template>
        </ng-container>

        <a
            class="button"
            [routerLink]="'/tasks/createFlight'"
            [queryParams]="{assessmentId: assessment?._id}"
            *appDesktop
            [ngClass]="{'disabled': isEdited$ | async}">
            New {{ assessment.formTypeSettings.name | formNameSimplifying }}
        </a>

        <ng-container *appDesktop nav-tabs>
            <ng-container *ngIf="(isEdited$ | async)">
                <app-nav-tabs *ngIf="navTabs$ | async as navTabs" [navTabs]="navTabs"></app-nav-tabs>
            </ng-container>
        </ng-container>
    </app-page-topbar>
</ng-container>

<ng-container *appMobile>
    <div *ngIf="isEdited$ | async" class="editor-header" [routerLink]="'/assessments/' + (assessment$ | async)?._id">
        <app-custom-icon
            [iconPath]="'assets/icons/general-icons.svg'"
            [imageName]="'icon-close'">
        </app-custom-icon>
        <span>Form Settings</span>
    </div>
    <app-page-topbar
        *ngIf="assessment$ | async as assessment"
        [title]="(isEdited$ | async) ? (currentTab$ | async)?.label : assessment?.title"
        [subTitle]="(isEdited$ | async) ? 'Form: ' + assessment?.title : 'Forms'"
        [top]="(isEdited$ | async) ? '38px' : '0'"
        [menu]="(isEdited$ | async) ? (navTabs$ | async) : []">
        <ng-container *ngIf="!(isEdited$ | async)">
            <div
                class="secondary-button"
                [routerLink]="'/assessments/' + assessment?._id + '/edit/questionnaire'">
                <app-custom-icon
                    [iconPath]="'assets/icons/general-icons.svg'"
                    [imageName]="'icon-cog'">
                </app-custom-icon>
            </div>
        </ng-container>
    </app-page-topbar>
</ng-container>


<div class="assessment" (click)="toggleMenu(false)">
    <router-outlet></router-outlet>
</div>
