@if (!(taskList$ | async)) {
    <app-loading-indicator></app-loading-indicator>
} @else {
    @if ((taskList$ | async)?.length === 0) {
        <app-card [noMargin]="true" [showHeader]="false">
            <div card-body class="table-empty-state h-auto mb-5">
                <img id="img-empty-tasks" class="empty" alt="Empty" src="/assets/img/empty-tasks.svg" />
                <h2>You’re All Caught Up</h2>
                <p>
                    Take a load off, there are no remaining
                    <br />
                    tasks for you to complete.
                </p>
            </div>
        </app-card>
    } @else {
        <div class="flights-list-wrapper">
            @for (flight of taskList$ | async; track flight; let index = $index) {
                <app-home-task-card
                    [flight]="flight"
                    [showEdit]="false"
                    [isLast]="index + 1 === (taskList$ | async)?.length"
                    [ngClass]="getFlightClass(flight)">
                </app-home-task-card>
            }
        </div>

        @if (total >= pageSize) {
            <p-paginator
                (onPageChange)="onPageChange($event)"
                [first]="1"
                [rows]="10"
                [totalRecords]="total"
                [showCurrentPageReport]="false"
                [showPageLinks]="false"
                [showJumpToPageDropdown]="true">
            </p-paginator>
        }
    }
}
