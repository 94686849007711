import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { DropdownModule } from "primeng/dropdown";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlightService } from "@/_services";
import { Observable, Subscription } from "rxjs";
import { AsyncPipe, DatePipe, NgTemplateOutlet } from "@angular/common";
import { DateTimeFormat } from "@/_utility/DateTimeFormat";
import { map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { ChartData } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import { PercentChangePipe } from "@/_pipes/nan-fix.pipe";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PerformanceChartComponent } from "@/components/home/home-crew-performance/performance-chart/performance-chart.component";

const lightChartLabelColor = "var(--chart-text)";

const labelOptions: Options = {
    color: lightChartLabelColor,
    font: {
        weight: "bold",
        size: 14,
    },
    display: (context) => {
        const point = context.dataset.data[context.dataIndex] as number;
        return point > 0;
    },
};

@UntilDestroy()
@Component({
    selector: "app-home-crew-performance",
    standalone: true,
    imports: [
        SharedComponentsModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        DatePipe,
        NgTemplateOutlet,
        PercentChangePipe,
        PerformanceChartComponent,
    ],
    templateUrl: "./home-crew-performance.component.html",
    styleUrl: "./home-crew-performance.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeCrewPerformanceComponent implements OnInit, OnDestroy {
    private flightService = inject(FlightService);

    @Input() isAdmin: boolean = false;

    readonly DateTimeFormat = DateTimeFormat;
    timeFrameList: RadioOption[] = [
        { label: "Past 7 Days", value: 7 },
        { label: "Past 30 Days", value: 30 },
        { label: "Past 60 Days", value: 60 },
        { label: "Past 90 Days", value: 90 },
        { label: "Past Year", value: 365 },
    ];
    timeFrameControl: FormControl<number> = new FormControl<number>(30);
    timeFrameSubscription: Subscription;

    stats$: Observable<any>;

    readonly averageChartOptions = {
        type: "line",
        plugins: {
            datalabels: labelOptions,
        },
        legend: { display: false },
        aspectRatio: 20,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        stepSize: 5,
                        autoSkip: false,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: { display: false },
                },
            ],
        },
    };

    readonly completionChartOptions = {
        type: "line",
        plugins: {
            datalabels: labelOptions,
        },
        legend: { display: false },
        aspectRatio: 20,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        stepSize: 1,
                        autoSkip: false,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: { display: false },
                },
            ],
        },
    };
    averageScoreDataSet$: Observable<ChartData>;
    completionDataset$: Observable<ChartData>;

    ngOnInit() {
        this.stats$ = this.timeFrameControl.valueChanges.pipe(
            untilDestroyed(this),
            startWith(this.timeFrameControl.value),
            switchMap((value) => {
                return this.flightService.getStats(value);
            }),
            shareReplay(),
        );

        this.averageScoreDataSet$ = this.stats$.pipe(
            map((stats) => {
                return {
                    labels: [0, 1, 2, 3], // TODO: replace with date interval
                    datasets: [
                        {
                            borderColor: "#1987CB",
                            fill: false,
                            data: stats.averageScores.currentPeriodValues,
                            label: "Current Period",
                        },
                        {
                            borderColor: "#A4B3CB",
                            fill: false,
                            data: stats.averageScores.previousPeriodValues,
                            label: "Previous Period",
                        },
                    ],
                };
            }),
        );

        this.completionDataset$ = this.stats$.pipe(
            map((stats) => {
                return {
                    labels: [0, 1, 2, 3], // TODO: replace with date interval
                    datasets: [
                        {
                            borderColor: "#1987CB",
                            fill: false,
                            data: stats.completionRate.currentPeriodValues,
                            label: "Current Period",
                        },
                        {
                            borderColor: "#A4B3CB",
                            fill: false,
                            data: stats.completionRate.previousPeriodValues,
                            label: "Previous Period",
                        },
                    ],
                };
            }),
        );
    }

    ngOnDestroy() {
        this.timeFrameSubscription?.unsubscribe();
    }
}
