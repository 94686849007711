<div class="flex flex-column gap-20px">
    <div class="flex flex-column">
        <h4 class="m-0 date-line">{{currentDate | date:'MMMM d, y':'UTC'}}</h4>
        <div class="flex flex-row align-items-center justify-content-between">
            <span class="welcome-line-1">
                    Welcome back, {{ (currentUser$ | async)?.firstName }}
                <img class="m-0" ngSrc="/assets/icons/welcome.svg" height="30" width="30" alt="Welcome">
            </span>

            <div class="flex-row gap-20px quick-actions-container">
                <ng-container *ngTemplateOutlet="quickActions"></ng-container>
            </div>
        </div>
        <span class="welcome-line-2">{{ currentQuote }}</span>
    </div>

    <div class="quick-actions-container-mobile flex-column">
        <ng-container *ngTemplateOutlet="quickActions"></ng-container>
    </div>

    <div class="content-container">
        <div class="flex flex-column gap-20px w-full">
            <h2 class="m-0">Recent Activity</h2>

            <app-nav-tabs [navTabs]="navTabList"></app-nav-tabs>

            <div>
                <router-outlet></router-outlet>
            </div>
        </div>

        <app-home-crew-performance
            class="w-full"
            [isAdmin]="isAdmin$ | async">
        </app-home-crew-performance>
    </div>
</div>

<ng-template #quickActions>
    <button class="btn-primary" (click)="submitAssessment()">Submit Assessment</button>

    @if (!(isAdmin$ | async)) {
        <button class="btn-secondary" (click)="fileReport()">File Report</button>
    } @else {
        <p-dropdown
            [styleClass]="'btn-secondary dropdown'"
            [options]="quickActionList"
            [placeholder]="'Manage'"
            appendTo="body">
            <ng-template pTemplate="selectedItem">
                Manage
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div (click)="item.onClick()">
                    <span>{{ item.label }}</span>
                </div>
            </ng-template>
        </p-dropdown>
    }
</ng-template>
