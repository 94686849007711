import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {
    BehaviorSubject,
    concat,
    Observable,
    of,
    shareReplay,
    tap,
} from "rxjs";
import { FlightModel } from "@/_models/flight/flight.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FlightService } from "@/_services";
import { FilterService } from "@/_services/filter.service";
import { debounceTime, map, switchMap } from "rxjs/operators";
import { Flight, MitigationState, UserDm } from "@/_models";
import { expandingFabAnimations } from "@/_utility/animations";
import { ActivatedRoute } from "@angular/router";
import UserRoles = UserDm.UserRoles;
import { AsyncPipe, NgClass } from "@angular/common";
import { PaginatorModule } from "primeng/paginator";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { HomeTaskCardComponent } from "@/components/home/home-task-card/home-task-card.component";

@Component({
    selector: "app-home-task-list",
    standalone: true,
    imports: [
        AsyncPipe,
        PaginatorModule,
        SharedComponentsModule,
        HomeTaskCardComponent,
        NgClass,
    ],
    templateUrl: "./home-task-list.component.html",
    styleUrls: ["./home-task-list.component.scss"],
    animations: [expandingFabAnimations],
})
export class HomeTaskListComponent implements OnInit {
    @Output() onFilterMenuToggle = new EventEmitter<void>();
    @ViewChild("taskArea") taskArea: ElementRef;

    taskList$: Observable<FlightModel[]>;

    currentSorting: { key: string; value: string } = {
        key: "flightDate",
        value: "desc",
    };

    filterForm: FormGroup;

    private page$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    pageSize: number = 10;
    total: number = 0;

    constructor(
        private flightService: FlightService,
        private formBuilder: FormBuilder,
        private filterService: FilterService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.filterForm = this.formBuilder.group({
            responsiblePilot: [""],
        });

        this.taskList$ = this.accumulateFlightList();
    }

    accumulateFlightList() {
        const currentUser = this.route.snapshot.data[
            "user"
        ] as UserDm.UserModel;

        // todo: get rid of this form since it's not wired up to actual controls.  It's value never changes.
        this.filterForm
            .get("responsiblePilot")
            .setValue(currentUser._id, { emitEvent: false });

        return concat(
            of(null), // reset the scroll container between filter changes
            this.page$.pipe(
                debounceTime(500),
                switchMap((page) => {
                    const filterParams = this.buildFilterQuery();
                    const sortParams = this.buildSortQuery();
                    return this.flightService.getFlights(
                        filterParams,
                        sortParams,
                        page,
                        this.pageSize,
                    );
                }),
                tap((list) => (this.total = list.meta["total"])),
                map((list) => list.data),
                shareReplay(),
            ),
        );
    }

    buildFilterQuery() {
        return this.filterService.buildFilterQuery(this.filterForm);
    }

    buildSortQuery() {
        const sortQuery = new URLSearchParams();
        if (this.currentSorting.key) {
            sortQuery.append(
                this.currentSorting.key,
                this.currentSorting.value,
            );
        }
        return sortQuery;
    }

    public getFlightClass(flight: Flight) {
        const state = flight.frat && flight.frat.mitigationState;
        switch (state) {
            case MitigationState.approved:
                return "approved";
            case MitigationState.needsMitigation:
                return "needs-mitigation";
            case MitigationState.submittedForApproval:
                return "waiting-approval";
            default:
                return "";
        }
    }

    onPageChange(event: any) {
        this.page$.next(event.page);
    }
}
