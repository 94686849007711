<div class="no-content">
    <img
        class="logo light-theme"
        src="/assets/icon/Logo-Light.svg"
        alt="Preflight Mitigator" />
    <img
        class="logo dark-theme"
        src="/assets/icon/Logo-White.svg"
        alt="Preflight Mitigator" />
    <div class="wrap">
        <img class="no-assessment" src="/assets/img/404-Error.svg" alt="404"/>
        <h1>
            Captain, check the instruments! It appears we've gone off course.
        </h1>
        <p>
            The page you are attempting to locate does not seem to exist. Turn
            this aircraft around.
        </p>
        <a class="btn btn-primary" (click)="flights()">Go Back Home</a>
    </div>
</div>
