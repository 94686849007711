import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { SubscriptionService } from "@/_services";
import { ISubscription } from "@/_models";
import { map } from "rxjs/operators";

export const activeTrialOnlyGuard: CanActivateFn = (route, state) => {
    const subscriptionService = inject(SubscriptionService);
    const router = inject(Router);

    return subscriptionService.subscriptionData().pipe(
        map((subscription: ISubscription) => {
            if (!subscription.isTrialActive()) {
                router.navigateByUrl("/home");
                return false;
            }
            return true;
        }),
    );
};
