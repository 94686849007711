<div class="flex flex-column gap-20px">
    <h2 class="m-0">{{ isAdmin ? 'Crew' : 'Your' }} Performance</h2>

    <p-dropdown
        class="time-frame-container"
        [styleClass]="'time-frame-select'"
        [autoDisplayFirst]="false"
        [options]="timeFrameList"
        [formControl]="timeFrameControl">
    </p-dropdown>

    @if (stats$ | async; as stats) {
        @if (stats.averageScores; as averageScores) {
            <app-card [showHeader]="false" [noMargin]="true">
                <div card-body class="flex flex-column performance-card">
                    <div class="flex justify-content-between align-items-center">
                        <h3 class="m-0">Average Score</h3>
                        <div class="diff blue">
                            {{averageScores.percentChange | percentChange }}%
                        </div>
                    </div>

                    <div class="flex justify-content-between align-items-center">
                        <div class="flex flex-column">
                            <h1 class="m-0">{{averageScores.currentPeriodAvg}}</h1>
                            <p class="timeframe">
                                {{stats.meta.currentPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - today
                            </p>
                        </div>

                        <div class="flex flex-column align-items-end">
                            <h1 class="m-0 prev">{{averageScores.previousPeriodAvg}}</h1>
                            <p class="timeframe text-right">
                                {{stats.meta.previousPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - {{stats.meta.previousPeriodEnd | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}}
                            </p>
                        </div>
                    </div>

                    @if(averageScoreDataSet$|async; as dataset) {
                        <div class="chart-wrapper">
                            <app-performance-chart class=w-full [options]="averageChartOptions" [data]="dataset"></app-performance-chart>
                        </div>
                    }
                </div>
            </app-card>
        }

        @if (stats.completionRate; as completionRate) {
            <app-card [showHeader]="false" [noMargin]="true">
                <div card-body class="flex flex-column performance-card">
                    <div class="flex justify-content-between align-items-center">
                        <h3 class="m-0">Completion Rate</h3>
                        <div class="diff blue">
                            {{completionRate.percentChange |percentChange}}%
                        </div>
                    </div>

                    <div class="flex justify-content-between align-items-center">
                        <div class="flex flex-column">
                            <div class="flex gap-5px">
                                <h1 class="m-0">{{ completionRate.currentPeriodCompletedPercent | percentChange }}%</h1>
                                <h5 class="m-0">({{completionRate.currentPeriodCompleted}}/{{completionRate.currentPeriodTotal}})</h5>
                            </div>
                            <p class="timeframe">
                                {{stats.meta.currentPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - today
                            </p>
                        </div>

                        <div class="flex flex-column align-items-end">
                            <div class="flex gap-5px">
                                <h5 class="m-0 prev">({{completionRate.previousPeriodCompleted}}/{{completionRate.previousPeriodTotal}})</h5>
                                <h1 class="m-0 prev">{{ completionRate.previousPeriodCompletedPercent | percentChange }}%</h1>
                            </div>
                            <p class="timeframe text-right">
                                {{stats.meta.previousPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - {{stats.meta.previousPeriodEnd | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}}
                            </p>
                        </div>
                    </div>

                    @if(completionDataset$|async; as dataset) {
                        <div class="chart-wrapper">
                            <app-performance-chart class=w-full [options]="completionChartOptions" [data]="dataset"></app-performance-chart>
                        </div>
                    }
                </div>
            </app-card>
        }

        <ng-container *ngTemplateOutlet="specificScores; context: { title: 'Severe Scores', stats: stats, scores: stats.severeScores }">
        </ng-container>

        <ng-container *ngTemplateOutlet="specificScores; context: { title: 'Moderate Scores', stats: stats, scores: stats.moderateScores }">
        </ng-container>

        <ng-container *ngTemplateOutlet="specificScores; context: { title: 'Mild Scores', stats: stats, scores: stats.mildScores }">
        </ng-container>
    }
</div>

<ng-template #specificScores let-title=title let-stats=stats let-scores=scores>
    <app-card [showHeader]="false" [noMargin]="true">
        <div card-body class="flex flex-column performance-card">
            <div class="flex justify-content-between align-items-center">
                <h3 class="m-0">{{ title }}</h3>
                <div class="diff blue">
                    {{scores.percentChange |percentChange}}%
                </div>
            </div>

            <div class="flex justify-content-between align-items-center">
                <div class="flex flex-column">
                    <h1 class="m-0">{{scores.currentPeriod}}</h1>
                    <p class="timeframe">
                        {{stats.meta?.currentPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - today
                    </p>
                </div>

                <div class="flex flex-column align-items-end">
                    <h1 class="m-0 prev">{{scores.previousPeriod}}</h1>
                    <p class="timeframe text-right">
                        {{stats.meta?.previousPeriodStart | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}} - {{stats.meta?.previousPeriodEnd | date:DateTimeFormat.NG_DATE_FORMAT_WITH_YEAR}}
                    </p>
                </div>
            </div>
        </div>
    </app-card>
</ng-template>
