import { Component, Inject, inject, OnInit } from "@angular/core";
import {
    AsyncPipe,
    DatePipe,
    NgOptimizedImage,
    NgTemplateOutlet,
} from "@angular/common";
import { Observable } from "rxjs";
import { UserDm } from "@/_models";
import UserModel = UserDm.UserModel;
import { USER_IS_AT_LEAST_ADMIN, UserService } from "@/_services";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { Router, RouterOutlet } from "@angular/router";
import { HomeCrewPerformanceComponent } from "@/components/home/home-crew-performance/home-crew-performance.component";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { AddonFeature } from "@/_models/addon-features";
import { filter, switchMap, tap } from "rxjs/operators";
import { AccountService } from "@/_services/account.service";
import { IssueService } from "@/_services/issue.service";
import { PromotionService } from "@/_services/promotion.service";
import { Issue } from "@/_models/issue.interface";
import { Promotion } from "@/_models/promotion.interface";
import { OverlayPanelModule } from "primeng/overlaypanel";

@Component({
    selector: "app-home",
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        NgOptimizedImage,
        SharedComponentsModule,
        RouterOutlet,
        HomeCrewPerformanceComponent,
        DropdownModule,
        FormsModule,
        OverlayPanelModule,
        NgTemplateOutlet,
    ],
    templateUrl: "./home.component.html",
    styleUrl: "./home.component.scss",
})
export class HomeComponent implements OnInit {
    private userService = inject(UserService);
    private router = inject(Router);
    private accountService = inject(AccountService);
    private issueService = inject(IssueService);
    private promoService = inject(PromotionService);

    currentDate: Date;
    currentUser$: Observable<UserModel>;

    currentQuote: string = "";
    quoteList: string[] = [
        "Safety doesn’t happen by accident.",
        '"An ounce of prevention is worth a pound of cure." – Benjamin Franklin',
        '"The best preparation for tomorrow is doing your best today." – H. Jackson Brown, Jr.',
        '"Risk comes from not knowing what you’re doing." – Warren Buffett',
        '"What gets measured gets managed." – Peter Drucker',
        "Preparedness is the key to safety.",
        '"Success is no accident." – Pele',
        '"Confidence comes from being prepared." – John Wooden',
        "Safety is a journey, not a destination.",
        "Small steps every day lead to big safety milestones.",
        "The greatest danger is in not taking risk seriously.",
        "Do it safely, or don’t do it at all.",
        '"Courage is knowing what not to fear." – Plato',
        '"By failing to prepare, you are preparing to fail." – Benjamin Franklin',
        "Your safety is your responsibility.",
        "Anticipate the unexpected.",
        "In safety, seconds save lives.",
        "Control the risk, or the risk will control you.",
        "Safety is a state of mind—accidents are an absence of mind.",
        "Plan for the worst, hope for the best.",
    ];

    quickActionList = [
        { label: "File Report", onClick: () => this.fileReport() },
        {
            label: "Start Corrective Action",
            onClick: () => this.startCorrectiveAction(),
        },
        { label: "Send Promotion", onClick: () => this.sendPromotion() },
        {
            label: "Manage Safety Objectives",
            onClick: () => this.manageSafetyObjectives(),
        },
        {
            label: "Manage Crew Members",
            onClick: () => this.manageCrewMembers(),
        },
    ];

    navTabList = [
        { label: "Tasks", link: "tasks" },
        /*{ label: "Mentions", link: "mentions" },*/
        { label: "Promotions", link: "promotions" },
    ];

    constructor(
        @Inject(USER_IS_AT_LEAST_ADMIN) public isAdmin$: Observable<boolean>,
    ) {}

    ngOnInit(): void {
        this.currentDate = new Date();
        this.currentUser$ = this.userService.getCurrentUserAsync();
        this.currentQuote =
            this.quoteList[Math.floor(Math.random() * this.quoteList.length)];
    }

    submitAssessment() {
        this.router.navigateByUrl("/tasks/createFlight?formFilter=assessments");
    }

    fileReport() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_ASSURANCE)
            .pipe(
                filter((result) => result),
                tap(() =>
                    this.router.navigateByUrl(
                        "/tasks/createFlight?formFilter=not-assessments",
                    ),
                ),
            )
            .subscribe();
    }

    startCorrectiveAction() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_ASSURANCE)
            .pipe(
                filter((result) => result),
                switchMap(() => this.issueService.createIssue()),
                tap((issue: Issue) =>
                    this.router.navigateByUrl(`/mitigations/${issue._id}`),
                ),
            )
            .subscribe();
    }

    sendPromotion() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_PROMOTION)
            .pipe(
                filter((result) => result),
                switchMap(() => this.promoService.createPromotion()),
                tap((promotion: Promotion) =>
                    this.router.navigateByUrl(`/promotions/${promotion._id}`),
                ),
            )
            .subscribe();
    }

    manageSafetyObjectives() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_POLICY)
            .pipe(
                filter((result) => result),
                tap(() => this.router.navigateByUrl("/policies/objectives")),
            )
            .subscribe();
    }

    manageCrewMembers() {
        this.router.navigateByUrl("/profile/users");
    }

    q(event) {
        console.log(event);
    }
}
