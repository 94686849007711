import { Component, inject, Input } from "@angular/core";
import { Promotion } from "@/_models/promotion.interface";
import { Router } from "@angular/router";
import { FieldExcludesFilterPipe } from "@/_pipes/field-excludes-filter.pipe";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { DateTimeFormat } from "@/_utility/DateTimeFormat";
import { DatePipe, NgClass } from "@angular/common";

@Component({
    selector: "app-home-promotion-card",
    standalone: true,
    imports: [
        FieldExcludesFilterPipe,
        SharedComponentsModule,
        DatePipe,
        NgClass,
    ],
    templateUrl: "./home-promotion-card.component.html",
    styleUrl: "./home-promotion-card.component.scss",
})
export class HomePromotionCardComponent {
    private router = inject(Router);

    @Input() promo: Promotion;
    @Input() isLast: boolean = false;
    protected readonly DateTimeFormat = DateTimeFormat;

    openPromotion(promoId: string) {
        return this.router.navigateByUrl(`/promotions/${promoId}`);
    }
}
