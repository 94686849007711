import { Component, Input } from "@angular/core";
import { UserService } from "@/_services";
import { CtaState, FlightCtaService } from "@/_services/flight-cta.service";
import { UserDm } from "@/_models";
import User = UserDm.User;
import { FlightModel } from "@/_models/flight/flight.model";
import { SharedComponentsModule } from "../../shared/shared-components.module";
import { FieldExcludesFilterPipe } from "@/_pipes/field-excludes-filter.pipe";
import { NgClass } from "@angular/common";

@Component({
    selector: "app-home-task-card",
    standalone: true,
    imports: [SharedComponentsModule, FieldExcludesFilterPipe, NgClass],
    templateUrl: "./home-task-card.component.html",
    styleUrl: "./home-task-card.component.scss",
})
export class HomeTaskCardComponent {
    @Input() flight: FlightModel;
    @Input() showEdit: boolean = false;
    @Input() isLast: boolean = false;

    ctaState: CtaState;
    currentUser: User;

    constructor(
        private userService: UserService,
        private flightCtaService: FlightCtaService,
    ) {
        this.currentUser = this.userService.getCurrentUser();
    }

    ngOnInit(): void {
        if (this.flight) {
            this.ctaState = this.flightCtaService.getCtaState(
                this.flight,
                this.currentUser,
            );
        }
    }
}
