import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Chart, ChartData, ChartOptions } from "chart.js";

@Component({
    selector: "app-performance-chart",
    standalone: true,
    imports: [],
    templateUrl: "./performance-chart.component.html",
    styleUrl: "./performance-chart.component.scss",
})
export class PerformanceChartComponent
    implements OnInit, AfterViewInit, OnChanges
{
    private chart: Chart;

    @Input() options: ChartOptions;
    @Input() data: ChartData;

    @ViewChild("chartCanvas", { static: true }) chartCanvas: ElementRef;

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.chart) {
            if (changes.data.currentValue) {
                this.chart.data = changes.data.currentValue;
                this.chart.update();
            }
        }
    }

    ngAfterViewInit(): void {
        this.chart = new Chart(this.chartCanvas.nativeElement, {
            type: "line",
            options: this.options,
            data: this.data,
        });
    }
}
