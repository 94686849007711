<div class="desktop-container"
     [ngClass]="{'last': isLast}"
     (click)="$event.stopPropagation()">
    <div>
        <span class="intro">
            @if (flight | getFormTitle:true; as title) {
                @switch (title.type) {
                    @case ('title') {
                        {{title.value}}
                    }
                    @case ('date') {
                        {{title.value}}
                    }
                    @case ('airports') {
                        {{title.value[0]}}
                        <app-custom-icon
                            [iconPath]="'assets/icons/general-icons.svg'"
                            [imageName]="'icon-chevron-right'">
                            </app-custom-icon>
                        {{title.value[1]}}
                    }
                }
            }
        </span>
        <p [innerHTML]="flight | taskDetails:(flight | getFormTitle:true)?.fields"></p>
    </div>

    <div class="form-type archived">
        @if (flight.assessment?.formTypeSettings?.type === 'generalForm') {
            General
        } @else {
            {{flight.assessment?.formTypeSettings?.type}}
        }
    </div>

    <app-frat-status [flight]="flight"></app-frat-status>

    <app-flight-card-cta [flight]="flight" [isEditable]="showEdit"></app-flight-card-cta>
</div>

<div class="mobile-container" (click)="$event.stopPropagation()">
    <app-flight-card-mobile [content]="'flex'">
        <ng-container title>
            @if (flight | getFormTitle:true; as title) {
                @switch (title.type) {
                    @case ('title') {
                        {{title.value}}
                    }
                    @case ('date') {
                        {{title.value}}
                    }
                    @case ('airports') {
                        {{title.value[0]}}
                        <app-custom-icon
                            [iconPath]="'assets/icons/general-icons.svg'"
                            [imageName]="'icon-chevron-right'">
                        </app-custom-icon>
                        {{title.value[1]}}
                    }
                }
            }
        </ng-container>
        <ng-container status>
            <app-frat-status [flight]="flight"></app-frat-status>
        </ng-container>
        <ng-container subtitle>
            <div class="form-type archived mt-3">
                @if (flight.assessment?.formTypeSettings?.type === 'generalForm') {
                    General
                } @else {
                    {{flight.assessment?.formTypeSettings?.type}}
                }
            </div>
        </ng-container>
        <ng-container content>
            @for (field of flight.fields; track field) {
                @if (field | fieldExcludesFilter:'fieldName':(flight | getFormTitle:true)?.fields) {
                    <div class="data-field">
                        <span>{{field.label}}: <span class="font-bold">{{(flight | flightField:field.fieldName)}}</span></span>
                    </div>
                }
            }
        </ng-container>
        <app-flight-card-cta actions [flight]="flight" [isEditable]="showEdit"></app-flight-card-cta>
    </app-flight-card-mobile>
</div>
