<div class="desktop-container"
     [ngClass]="{'last': isLast}"
     (click)="$event.stopPropagation()">
    <div>
        <span class="intro">{{promo.title || "--"}}</span>
        <p>{{promo.creator.abbreviatedName}} sent you a safety promotion on {{promo.sentDate | date:DateTimeFormat.NG_DATE_FORMAT:'UTC'}}</p>
    </div>

    <div class="promotion-type archived">
        Promotion
    </div>

    <div class="status-field {{promo.status}}">
        {{promo.status}}
    </div>

    <button class="btn-primary" (click)="openPromotion(promo._id)">
        Open
    </button>
</div>

<div class="mobile-container" (click)="$event.stopPropagation()">
    <app-flight-card-mobile [content]="'flex'">
        <ng-container title>
            {{promo.title}}
        </ng-container>
        <ng-container status>
            <div class="status-field {{promo.status}}">
                {{promo.status}}
            </div>
        </ng-container>
        <ng-container subtitle>
            <div class="promotion-type archived mt-3">
                Promotion
            </div>
        </ng-container>
        <ng-container content>
            <span>{{promo.creator.abbreviatedName}} sent you a safety promotion on {{promo.sentDate | date:DateTimeFormat.NG_DATE_FORMAT:'UTC'}}</span>
        </ng-container>
        <ng-container actions>
            <button class="btn-primary" (click)="openPromotion(promo._id)">
                Open
            </button>
        </ng-container>
    </app-flight-card-mobile>
</div>
